import { createRouter, createWebHistory } from '@ionic/vue-router';

import store from '../store'
import {homeRouteForUser} from "@/router/helpers";

import {CustomerRoutes} from "./customer";
import {CourierRoutes} from "./courier";

const routes = [
  {
    path: '',
    component: () => import ('../views/LandingPage.vue'),
    meta:{
      require_auth:false,
      redirect_if_auth:false
    }
  },


  {
    path: '/register',
    component: () => import ('../views/auth/RegistrationPage.vue'),
    meta:{
      require_auth:false,
      redirect_if_auth:true
    }
  },

  {
    path: '/courier-registration',
    name: 'courier.register',
    component: () => import ('../views/auth/CourierRegistration'),
    meta:{
      require_auth:false,
      redirect_if_auth:true
    }
  },

  {
    path: '/login',
    component: () => import ('../views/auth/LoginPage.vue'),
    name: 'login',
    meta:{
      require_auth:false,
      redirect_if_auth:true
    },
  },

  {
    path: '/orders/:id',
    component: () => import ('../views/courier/OrderDetail.vue'),
    name: 'guest.order_detail',
    meta:{
      require_auth:false,
    },
  },

  ...CustomerRoutes,

  ...CourierRoutes,

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  //document.title = `${to.meta.title} - ${process.env.MIX_APP_NAME}`
  const authRequired = to.meta.require_auth //|| to.parent.meta.require_auth;
  const redirectIfAuth = to.meta.redirect_if_auth;
  let authenticated = store.state.auth.authenticated;
  const loginQuery = { path: "/login", query: { redirect: to.fullPath } };

  if(!authenticated){
    console.log('authenticated appears to be false. rechecking with the backend...')

    await store.dispatch("auth/getUser");
    authenticated = store.state.auth.authenticated
  }


  console.log('router.beforeEach','authRequired',authRequired, 'authenticated', authenticated)

  if(authRequired && !authenticated) {
    console.log('auth is required and not authenticated')

    next(loginQuery);


  }else if(!authRequired && authenticated && redirectIfAuth){
    console.log('auth not required, authenticated so redirecting...')
    next(homeRouteForUser())
  } else{
    next()
  }
})

export default router
