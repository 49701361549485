import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import {Api} from "@/api";

//Pusher.logToConsole = true;
window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    forceTLS: true,
    cluster: 'eu',


    authorizer: (channel) => {
        return {
            authorize: (socketId, callback) => {
                Api.post('/broadcasting/auth', {
                    socket_id: socketId,
                    channel_name: channel.name
                })
                    .then(response => {
                        console.log('authorizer response',response)
                        callback(false, response.data);
                    })
                    .catch(error => {
                        callback(true, error);
                        console.log('authorizer error',error)
                    });
            }
        };
    },
});
