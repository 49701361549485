import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from '@/store'

import { IonicVue } from '@ionic/vue';
import { createHead } from "unhead"

// Create a global head instance
createHead()

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

//Echo setup
import './echo'

const app = createApp(App)
    .use(IonicVue, {mode:'md'})
    .use(store)
    .use(router);

router.isReady().then(() => {
  app.mount('#app');
});

import posthog from 'posthog-js'

if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
  posthog.init('phc_HFQCuOI5ydynV63SvAmb37NGShsF43rWRk59xGhkyJJ',
      { api_host: 'https://app.posthog.com' })
}



