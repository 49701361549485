import axios from 'axios'

axios.defaults.withCredentials = true;



const headers = {
    "Content-Type": "application/json",
    "Accept": "application/json"
};


const baseUrl = process.env.VUE_APP_API_BASE_URL//'http://localhost/jeda/mulo-ng/mulo-api/public/api'//

const Api = axios.create({
    baseURL: baseUrl,
    //timeout: 1000,
    headers: headers,
    withCredentials: true
});

export {Api}
