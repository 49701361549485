import Vuex from 'vuex'
import auth from './auth.js'


export default new Vuex.Store({

    modules:{
        auth
    }
})
