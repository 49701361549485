const CourierRoutes = [{
    path: '/courier',
    component: () => import ('@/views/courier/DashboardContainer.vue'),
    meta:{
        require_auth:true,
    },
    children:[
        {
            path: 'home',
            component: () => import ('@/views/courier/HomePage.vue')
        },
        {
            path: 'available-orders',
            component: () => import ('@/views/courier/OrderList.vue'),
            name:'courier.available_orders'
        },
        {
            path: 'awarded-orders',
            component: () => import ('@/views/courier/AwardedOrders.vue')
        },
        {
            path: 'orders/:id',
            component: () => import ('@/views/courier/OrderDetail.vue'),
            name:'courier.order_detail'
        },
    ]
}]

export {CourierRoutes}
