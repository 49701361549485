import {Api} from "@/api";

export default {
    namespaced: true,
    state:{
        authenticated:false,
        user:{}
    },
    getters:{
        authenticated(state){
            return state.authenticated
        },
        user(state){
            return state.user
        }
    },
    mutations:{
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value
        },
        SET_USER (state, value) {
            state.user = value
        }
    },
    actions:{
        getUser({commit}){
            return Api.get('/user').then(({data})=>{
                console.log('auth login data',data)
                commit('SET_USER',data)
                commit('SET_AUTHENTICATED',true)
            }).catch((err)=>{
                console.log('failed to fetch user',err)
                commit('SET_USER',{})
                commit('SET_AUTHENTICATED',false)
            })
        },
        logout({commit}){
            commit('SET_USER',{})
            commit('SET_AUTHENTICATED',false)
        }
    }
}
