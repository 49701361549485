const CustomerRoutes = [{
    path: '/customer',
    component: () => import ('../views/customer/DashboardContainer.vue'),
    meta:{
        require_auth:true,
    },

    children:[
        {
            path: 'folder/:id',
            component: () => import ('../views/FolderPage.vue')
        },
        {
            path: 'home',
            component: () => import ('../views/customer/HomePage.vue'),
            name:'customer',
        },
        {
            path: 'new-order',
            component: () => import ('../views/customer/CreateOrder.vue')
        },
        {
            path: 'orders',
            component: () => import ('../views/customer/OrderList.vue'),
            name:'customer.order_list',
        },
        {
            path: 'orders/:id',
            component: () => import ('../views/customer/OrderDetail.vue'),
            name:'customer.order_detail',
        },
    ]
}]

export {CustomerRoutes}
